<script>
export default {
    props: ['width', 'bgColor', 'top'],
    data(){
        return {
           areaWidth: this.width == undefined || this.$isMobile ? '80%' : this.width,
           height: $(window).height() - 80 + 'px',
           boxTop: this.top == undefined ? '20px' : this.top
        }     
    }
}
</script>
<template>
<div class="PopUp-bg" v-fade-in="200">
    <div class="PopUp-area" :style="{ 'width': areaWidth, 'max-height': height, 'top': boxTop, 'background-color': bgColor }">
        <slot></slot> <!-- slot outlet -->
    </div>
</div>
</template>

<style scoped>
    .PopUp-bg
    {
        position: fixed;
        left: 0px;
        top: 0px;
        width:100%;
        height:2000px;
        background-color: rgba(0,0,0,0.6);
        z-index:22000;
    }

    .PopUp-area
    {
        position: absolute;
        top: 20px;
        width: 80%;
        margin: auto;
        left: 0px;
        right: 0px;
        background-color: white;
        padding:20px;
        overflow-y: auto;
    }

    @media only screen and (max-width: 1000px) {
    .PopUp-area
    {
        top: 20px;
    }
}
</style>