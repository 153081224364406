<script>

import Logo from '@/components/Logo.vue'
export default {
    components: {Logo},
    created() {  
    },
    data() {
        return { 
           
        }
    },   
    methods:
    {
        goTopage(page)
        {
            $(window).scrollTop(0);   
                this.$router.push({ name: page })
                
        },
    }
}
</script>
<template>
    <div style="overflow: auto;" :style="{'min-height': this.$windowHeight-200 + 'px'}">
        <section class="SaleQnA" v-fade-in>
            <h1>Frågor & Svar</h1>
            <h2>Fråga: Vad är Jamii?</h2>
            Svar: Jamii är ett smart verktyg på nätet för föreningar.
             Ni kan chatta, boka gemensamma platser, ha koll på alla medlemmar och skapa en egen sida för ert område och mycket mer.
            <h2>Fråga: Vilka saker kan vi göra med Jamii?</h2>
            Svar: Ni kan göra er egna hemsida, boka tvättstuga eller festlokal, organisera träffar och mycket mer.
            <h2>Fråga: Behöver vi vara bra på datorer för att använda Jamii?</h2>
            Svar: Nej, det är superenkelt. Om du kan använda internet, kan du använda Jamii.
            <h2>Fråga: Är våra saker privata på Jamii?</h2>
            Svar: Ja, vi ser till att allt ni gör och all information ni delar är säker och bara för er att se.
            <h2>Fråga: Kostar det något?</h2>
            Svar: Det kostar bara 200 kr i månaden. Inga konstigheter, allt smart finns med för det priset.
            <h2>Fråga: Hur får vi hjälp om vi undrar något?</h2>
            Svar: Ni behöver nog inte hjälp för det är så lätt att använda, men om ni undrar något finns det massa info och tips direkt i appen. 
            Skulle ni ändå behöva extra hjälp, går det alltid att maila oss på <b>support@jamii.se</b>.
            <h2>Fråga: Kan vi bestämma hur vår hemsida på Jamii ser ut?</h2>
            Svar: Ja, ni kan göra sidan precis som ni vill. Välj färger och lägg till bilder för att visa ert område från sin bästa sida.
            <h2>Fråga: Kan vem som helst se vad vi skriver och planerar?</h2>
            Svar: Nej, bara de ni väljer. Ni bestämmer själva vad som ska vara öppet på er hemsida och vad som bara är för er som är medlemmar i området. 
            Så ni har full kontroll!
            <h2>Fråga: Fungerar Jamii på mobilen?</h2>
            Svar: Japp, det funkar fint på både mobilen, surfplattan och datorn. Ni kan hålla koll på allt viktigt var ni än är.
            <h2>Fråga: Hur snabbt kan vi börja använda Jamii?</h2>
            Svar: Superfort! Bara registrera er <b><a @click="goTopage('saleOrder')">här</a></b> så är ni igång på nolltid.

        </section>
    </div>
</template>
<style scoped>

    h1{
        margin: 50px 0px 30px 0px;
    }

    h2{
        margin-top: 20px;
    }

    .SaleQnA
    {
        width: 90%;
        margin: auto;
        margin-top: 90px;
        max-width: 1000px;
        overflow: auto;
        color:  rgba(31, 57, 87, 1);
        margin-bottom: 50px;
    }

    @media only screen and (max-width: 1000px) {

    }


</style>