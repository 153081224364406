import store from '../store'
import communicator from './communicator'
import router from '../router'
export default {
  createCache(entity, url, data)
  {                 
    var self = this;
    if(store.state[entity] == null)
    {
      communicator.get(url, data)
          .then(function(data)
          {             
              store.commit('setEntity', { entityName: entity, entity: data })
          })
          .fail(function(error) {
            if(error.status == 401)
            {
              router.push('/login')  
            }
          });  
    }           
  },

  updateCache(entity, url)
  {                 
    communicator.get(url)
        .then(function(data)
        {             
            store.commit('setEntity', { entityName: entity, entity: data })
        })
        .fail(function(error) {
        
        });  
             
  },
  clearCache()
      {
      // store.commit('setEntity', { entityName: 'user', entity: null })
      // store.commit('setEntity', { entityName: 'members', entity: null })
      // store.commit('setEntity', { entityName: 'boardMembers', entity: null })
      // store.commit('setEntity', { entityName: 'forums', entity: null })
      // store.commit('setEntity', { entityName: 'modules', entity: null })
      // store.commit('setEntity', { entityName: 'customer', entity: null })
      // store.commit('setEntity', { entityName: 'users', entity: null })
      // store.commit('setEntity', { entityName: 'notifications', entity: null })
      // store.commit('setEntity', { entityName: 'bookings', entity: null })
      // store.commit('setEntity', { entityName: 'availableBookings', entity: null })
      // store.commit('setEntity', { entityName: 'invitations', entity: null })
      // store.commit('setEntity', { entityName: 'message', entity: null })
      // store.commit('setEntity', { entityName: 'userMessages', entity: null })
      // store.commit('setEntity', { entityName: 'documents', entity: null })
      // store.commit('setEntity', { entityName: 'workGroups', entity: null })
      // store.commit('setEntity', { entityName: 'userWorkGroups', entity: null })
      // store.commit('setEntity', { entityName: 'recipientGroups', entity: null })
      // store.commit('setEntity', { entityName: 'incomingEvents', entity: null })
      }
}