import { createStore } from 'vuex'

const store = createStore({
    state: {
      publicPage: null,
      message: null
    },
    mutations: {
      setEntity(state, payload){
          state[payload.entityName] = payload.entity;
      },
      
    }
  })

export default store
