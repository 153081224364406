<script>
import SaleHomeWhatIs from '@/views/SaleSite/SaleHomeWhatIs.vue'
import SaleHomePoints from '@/views/SaleSite/SaleHomePoints.vue'
import SaleHomeWhoAreWe from '@/views/SaleSite/SaleHomeWhoAreWe.vue'
export default {
    components: { SaleHomeWhatIs, SaleHomePoints, SaleHomeWhoAreWe },
    props:['windowScroll'],
    created() {  
        var self = this;
        this.startIntervalSlider();
        setTimeout(() => {
            self.selectedSlide = 1;
        }, 100);
    },
    data() {
        return {  
            hasScrolledOnce: false,
            selectedSlide: 0,
            sliderInterval: null        
        }
    },
    methods:
    {
        startIntervalSlider()
        {
            clearInterval(this.sliderInterval);
            var self = this;
            this.sliderInterval = setInterval(() => {
                if(self.selectedSlide == 3)
                {
                    self.selectedSlide = 1;
                }
                else
                {
                    self.selectedSlide++;
                }
            }, 10000);
        },
        scrollToSection() {
            const scrollDistance = window.innerHeight * 0.5; 
            $("html, body").animate({ scrollTop: scrollDistance });
            this.hasScrolledOnce = true;
        },
        scrollToElement(elementId) {
            var scrollTop = document.getElementById(elementId).getBoundingClientRect().top + document.documentElement.scrollTop - 100;
            $("html, body").animate({ scrollTop: scrollTop });
        },
        moveSlider(to)
        {
            var marginLeft = 0;
            switch(to) {
                case 2:
                marginLeft = 1000;
                case 3:
                    marginLeft = 2000;
                    
            }
        },
        changeSlider(isRight)
        {
            this.startIntervalSlider();
            
            if(isRight)
            {
                this.selectedSlide++;
            }
            else
            {
                this.selectedSlide--;
            }
        },
        goTopage(page)
        {
            $(window).scrollTop(0);   
                this.$router.push({ name: page })
                
        },
        scrollToSection() {
            const scrollDistance = window.innerHeight * 0.9; 
            $("html, body").animate({ scrollTop: scrollDistance });
            this.hasScrolledOnce = true;
        }
    },
    computed: {
        height() { return window.innerHeight + 'px' },
        selectedBackground() {   
            return this.$backgroundColors[this.selectedSlide];     
        }
    },
    watch:
    {
        windowScroll(newvalue)
        {
            if(newvalue > 1000)
            {
                this.hasScrolledOnce = true;
            }

            if(newvalue == 0)
            {
                this.startIntervalSlider();
            }
            else{
                clearInterval(this.sliderInterval);
            }
        }
    }
}
</script>
<template>
   <section v-fade-in>
        <div class="top-public" :style="{ 'height': height }">
            <button class="btn SaleHome-order-btn" @click="goTopage('saleOrder')">Beställ nu för<span>200kr/mån</span></button>
            <div class="SaleHome-appetizer" :class="[selectedSlide == 1 ? 'SaleHome-appetizer-left':'', selectedSlide == 2 ? 'SaleHome-appetizer-middle':'', selectedSlide == 3 ? 'SaleHome-appetizer-right':'', ]">   
               <section :style="{ background: this.$backgroundColors[1] }">
                    <div class="SaleHome-appetizer-content">                       
                        <h1>Jamii <br>- För din förening</h1>
                        <p> Låt oss digitalisera er förening!</p>
                        <button  @click="scrollToSection">Hur?</button>
                        <button @click="goTopage('saleOrder')">Beställ</button>                       
                    </div>
                    <div class="SaleHome-appetizer-img SaleHome-appetizer-img-hands" :style="{ 'height': height }">
                    </div>                 
               </section>
               <section :style="{ background: this.$backgroundColors[2] }">
                    <div class="SaleHome-appetizer-content">
                        <h1>Allt i Jamii</h1>
                        <p>Jamii är designat för att ge er full kontroll och översikt över er förening. Från bokningar till forumdiskussioner, vi samlar alla verktyg ni behöver på ett ställe.<br> </p>
                        <button @click="scrollToElement('SaleHomeHelpYou')">Läs mer här</button>
                    </div>
                    <div class="SaleHome-appetizer-img SaleHome-appetizer-img-boats" :style="{ 'height': height }">
                    </div>
               </section>
               <section :style="{ background: this.$backgroundColors[3] }">
                    <div class="SaleHome-appetizer-content">                  
                        <h1>Är Jamii för oss?</h1>
                        <p>Oavsett om ni är en stor eller liten förening, en bostadsrättsförening eller samfällighet, en båtklubb eller en skytteförening,
                            så kommer jamii göra er förening ännu bättre.
                        </p>  
                        <button @click="goTopage('saleTrial')">Prova på Jamii</button>         
                    </div>
                    <div class="SaleHome-appetizer-img SaleHome-appetizer-img-windows" :style="{ 'height': height }">
                    </div>
               </section>
            </div>  

            <div class="SaleHome-slider-buttons">
                <button @click="changeSlider()" :disabled="selectedSlide == 1" :class="selectedSlide == 1 ? 'SaleHome-slider-button-disabled' :''"> &lt; </button>
                <button @click="changeSlider(true)" :disabled="selectedSlide == 3" :class="selectedSlide == 3 ? 'SaleHome-slider-button-disabled' :''"> > </button>
            </div>   
            
            <div class="SaleHome-slider-progress">
                <div>
                    <p :class="selectedSlide == 1 ? 'SaleHome-slider-in-progress' : ''">                      
                    </p>                                  
                </div>
                <div>
                    <p :class="selectedSlide == 2 ? 'SaleHome-slider-in-progress' : ''">                      
                    </p>                                  
                </div>
                <div>
                    <p :class="selectedSlide == 3 ? 'SaleHome-slider-in-progress' : ''">                      
                    </p>                                  
                </div>                    
            </div>
        </div>
    </section>  
    <SaleHomeWhatIs :windowScroll="windowScroll" :bgColor="this.$backgroundColors[this.selectedSlide]"></SaleHomeWhatIs>
    <SaleHomePoints :windowScroll="windowScroll"></SaleHomePoints>
    <SaleHomeWhoAreWe :windowScroll="windowScroll"></SaleHomeWhoAreWe>
</template>
<style scoped>

main 
{
    margin: 0px;
    padding: 0px;

}

.top-public{
    overflow: hidden;  
    background: none; 
    margin: 0px;
    padding: 0px;
}

.SaleHome-order-btn
{
    position:absolute;
    top:60px;
    right: 40px;
    font-size: 17px;
    padding: 80px 30px;
    border-radius: 140px;
    text-shadow: 1px 1px 1px rgb(0, 0, 0);
    transform: rotate(15deg);
    box-shadow: 1px 1px 1px rgb(122, 122, 122);
    background: radial-gradient(circle, rgba(35,80,106,1) 0%, rgba(44,104,139,1) 100%);
}

.SaleHome-order-btn span{
    display: block;
    font-size: 30px;
    font-family: "Fredoka", sans-serif;
    font-weight: 600;

}

.SaleHome-slider-buttons
{
    position: absolute;
    bottom: 40px;
    right: 40px;
}

.SaleHome-slider-buttons button
{
    padding: 20px;
    border-radius: 40px;
    width: 62px;
    font-weight: bold;
    margin: 10px !important;
}

.SaleHome-slider-buttons button:hover
{
    background-color: rgb(204, 166, 110);
    transition: ease 1;
}

.SaleHome-slider-button-disabled
{
    background-color: #ddd !important;
}

.SaleHome-appetizer
{
    width: 300%;  
    display: flex;   
}

.SaleHome-appetizer section
{
    width: 33.33333333333%;
    display: flex;
    align-items: center;
}

.SaleHome-appetizer-content
{
    width: calc(50% - 180px);  
    color: white;
    padding: 0px 80px 0px 100px;   
}

.SaleHome-appetizer-img
{
    width: 50%;
    display:inline-block;       
    background-size: cover;
    background-position: center;
}

.SaleHome-appetizer-img-boats
{
    background-image: url('@/assets/imgs/boats.jpg');
}

.SaleHome-appetizer-img-hands
{
    background-image: url('@/assets/imgs/hands.jpg');
}

.SaleHome-appetizer-img-windows
{
    background-image: url('@/assets/imgs/windows.jpg');
}


.SaleHome-appetizer-left
{
    margin-left: 0%;
}
.SaleHome-appetizer-middle
{
    margin-left: -100%;
}

.SaleHome-appetizer-right
{
    margin-left: -200%;
}

.SaleHome-appetizer-left, .SaleHome-appetizer-middle, .SaleHome-appetizer-right
{
    transition: ease 1s;
}

.SaleHome-appetizer h1{
    font-size: 44pt;
    margin-bottom: 17px;
}

.SaleHome-appetizer p
{
    font-size: 21px;
    font-weight: 700;
    line-height: 35px;
}

button{
    padding:15px 50px;
    margin: 20px 40px 0px 0px;
    font-size: 20px;
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(225, 182, 117);
    color: white;
    border: 0px;
    border-radius: 6px;
    cursor: pointer;
    letter-spacing: 1px;
}

.SaleHome-slider-progress
{
    position: absolute;
    left:  75px;
    bottom: 50px;
    width: 600px;
}
.SaleHome-slider-progress div
{
    width: calc(33% - 20px);
    display:inline-block;
    margin: 10px;
    height: 2px;
    background-color: grey;
}

.SaleHome-slider-progress p
{     
    width:0px;
    background-color: white;
    height: 2px;
    margin: 0px;
        
}

.SaleHome-slider-in-progress
{
    width: 100% !important;
    transition: ease 10s;
}

@media only screen and (max-width: 1400px) {
    .SaleHome-appetizer h1{
        font-size: 22pt;
    }
}

@media only screen and (max-width: 1000px) {
    .SaleHome-appetizer
    {
        width: 300%;
    }

    .SaleHome-order-btn
    {
        top:60px;
        right: 20px;
        font-size: 13px;
        padding: 46px 10px;
        border-radius: 140px;
    }

    .SaleHome-order-btn span{
        font-size: 20px;
    }

    .SaleHome-appetizer-content
    {
        width: calc(50% - 30px);
        padding: 0px 15px;
        
    }

    .SaleHome-appetizer h1{
        font-size: 19pt;
    }

    .SaleHome-appetizer p
    {
        font-size: 14px;
        line-height: 25px;
    }

    .SaleHome-slider-progress
    {
        bottom: 10px;
        left: 0px;
        width: 100%;
    }

    .SaleHome-slider-progress div
    {
        width: calc(33% - 20px);
        display:inline-block;
        margin: 10px;
        height: 4px;
        background-color: grey;

    }
    
    .SaleHome-slider-progress p
    {     
        width:0px;
        background-color: white;
        height: 4px;
        margin: 0px;
            
    }

    button{
        margin: 20px 0px 0px 0px;
        padding: 10px 20px;
        display: block;
        font-size: 16px;
    }

    .SaleHome-slider-buttons
    {
        position: absolute;
        bottom: 40px;
        right: 20px;
    }
    
    .SaleHome-slider-buttons button
    {
      display: inline-block;
    }
    
    .SaleHome-slider-buttons button
    {
        padding: 10px;
        border-radius: 20px;
        width: 42px;
        font-weight: bold;
        margin: 10px !important;
    }
}



</style>