<script>
import Logo from '@/components/Logo.vue'
export default {
    components: { Logo },
    props:['windowScroll'],
    created() {      
    },
    data() {
        return {  
            showHeader: false,
            showContent: false,
            contents: [
                { header: 'Administrera <br>Föreningen', text: 'Adminstrera<br>* Styrelse <br>* Arbetsgrupper<br>* Medlemmar<br> * Stadgar<br> * Ordningsregler' },
                { header: 'Kommunicera <br>mera', text: ' Kommunicera inom föreningen genom<br>* Aktuellt från styrelsen<br> * Skicka ut inbjudan och kallelser<br>* Privata meddelanden mellan medlemmar' },
                { header: 'Forumet<br><br>', text: '* Skapa upp de forum som passar just din förening<br>* Komma i kontakt med styrelsen och arbetsgrupperna genom forumet.' },
                { header: 'Boka<br><br>', text: '* Intern bokning för medlemmarna<br>* Extern bokning genom den publika sidan.<br> * Fritt antal bokningsobjekt<br>' },
                { header: 'Publika <br>hemsidan', text: '* Skapa enkelt föreningens egna hemsida<br>* Möjlighet att ge sidan en personlig känsla<br>' },
                { header: 'Skicka <br>Inbjudan', text: '* Skapa inbjudan och välj enkelt mottagare<br>* Få en sammanställning av deltagandet<br>* Bifoga dokument' },
                { header: 'Bild och Dokumenthantering', text: '* Ladda upp och organisera föreningens dokument<br> * Hantera de bilder som används i appen och på den publika sidan' },
                { header: 'Framtidens Jamii', text: '<b>Ständigt på G med nytt:</b> Uppdateringar och nya grejer baserade på vad just du vill ha.<br><b>Utvecklas med er:</b> Era förslag är vår to-do-lista. Vi fixar det tillsammans!<br><b>Nya finesser på önskelistan:</b> Har ni en idé? Vi är pepp på att testa nya saker som gör livet i föreningen bättre.' },
            ]
        }
    },
    watch:
    {
        windowScroll(newvalue)
        {
            var self = this;
            if(newvalue > $(".SaleHomeWhatIs").height() + 300)
            { 
                setTimeout(() => {                  
                    self.showHeader = true;
                }, 300);            
            }
            
            if(newvalue > $(".SaleHomeWhatIs").height() + 400)
            { 
                setTimeout(() => {              
                    self.showContent = true;
                }, 300);            
            }
        }
    }
}
</script>
<template>   
        <section class="SaleHomePoints" id="SaleHomePoints">
            <div style="height: auto; overflow: hidden;">
                <div class="SaleHomePoints-header-and-links" :class="showHeader ? 'SaleHomePoints-header-and-links-visible' :''">
                    <h1>Jamii i punkter</h1>              
                </div>      
            </div>  
            <div class="SaleHomePoints-boxes">
                <ul v-if="showContent" v-fade-in>
                    <li v-for="(content,index) in contents" v-fade-in>
                            <h2 v-html="content.header"></h2>
                        <div v-html="content.text"></div>                     
                    </li>
                </ul>         
            </div>
        </section>
</template>
<style scoped>

.SaleHomePoints-header-and-links
{
    opacity: 0;
    margin-top: 100px;
}

.SaleHomePoints-header-and-links-visible
{
    opacity: 1;
    margin-top: 0px;
    transition: ease 1.4s;
}

.SaleHomePoints
{
    width: 100%;
    margin: auto;
    overflow: auto;
    background-color: #f7f7f7;
    padding-top: 50px;
    padding-bottom: 100px;
}

.SaleHomePoints-boxes
{
   
    overflow: auto;
    min-height: 600px;
}


.SaleHomePoints ul
{
    list-style: none;
    margin: 0px;
    padding: 20px;
}

.SaleHomePoints li
{
    position: relative;
    float: left;
    width: calc(25% - 62px);
    margin: 0px 10px;
    padding: 40px 20px;
    background-color: white;
    height: 300px;
    line-height: 25px;
    margin-bottom: 10px;
    border-radius: 20px;
    border: 1px solid #ddd;
    box-shadow: 2px 2px 14px 0px #ddd;
}

h1
{
    font-size: 23pt;
    text-align: center;
}

h2{
    font-size: 19px;
    letter-spacing: 1px;
}

@media only screen and (max-width: 1400px) and (min-width: 1000px) {
    h2{
        font-size: 15px;
        letter-spacing: 1px;
    }

    .SaleHomePoints li
    {
       font-size: 13px;
        width: calc(25% - 52px);
        margin: 0px 5px 10px 5px;      
    }
}

@media only screen and (max-width: 1000px) {
    .SaleHomePoints 
    {       
        padding-bottom: 20px;
    }
    
    .SaleHomePoints ul
    {
        list-style: none;
        display: block;
        padding: 0px;
        margin: 20px 0px 46px 0px;

    }

    .SaleHomePoints li
    {
        position: relative;
        display: block;
        width:calc(90% - 40px);
        margin: auto;
        padding: 20px;
        background-color: white;
        height: auto;
        line-height: 25px;
        margin-bottom:20px;
        float: none; 
        padding-bottom: 135px;
       }

    .SaleHomePoints-boxes
    {
        height: auto;
        min-height: 600px; 
        overflow: auto;
    }
            
}
</style>