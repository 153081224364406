import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import cache from './helpers/cache'
import adaptHtml from './helpers/adaptHtml'
import  './assets/css/global.css'
import  './assets/css/directives.css'
import JQuery from 'jquery'
import router from './router'
import communicator from './helpers/communicator'
import directives from './helpers/directives'

window.$ = JQuery
const app = createApp(App);

//add globals
app.config.globalProperties.$communicator = communicator
app.config.globalProperties.$cache = cache
app.config.globalProperties.$adaptHtml = adaptHtml
app.config.globalProperties.$isMobile = window.innerWidth < 900
app.config.globalProperties.$windowHeight = window.innerHeight
app.config.globalProperties.$backgroundColors = ["", "rgb(18 41 16)","rgb(2 49 71)","rgba(75,55,24,1)"]

app.directive('date', directives.date)
app.directive('fade-in', directives.fadeIn)

app.use(router).use(store).mount('#app');