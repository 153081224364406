<script>
import config from "@/helpers/config"
import slide1 from '@/assets/imgs/compz2.png'
import slide2 from '@/assets/imgs/booking-example.png'
import slide3 from '@/assets/imgs/public-homz.png'
import PopUp from '@/components/PopUp.vue'
export default {
    components: { PopUp },
    props:['windowScroll','bgColor'],
    created() {  
      
    },
    data() {
        return {  
            slide1: slide1,
            slide2: slide2,
            slide3: slide3,
            selectedImage: null,
            showHeader: false,
            showContent: false,
            slide: 1,
            links: [
                {title: 'Förbättrad Kommunikation', id: 1 },
                {title: 'Bättre Tillgänglighet', id: 2 },
                {title: 'Ökad Synlighet', id: 3 },
            ],
            contents: [
                { img: 'slide1', header: 'Gör Föreningslivet Mer Levande Med Jamii', text: '', link: 'hej' },
                { img: 'slide2', header: 'Jamii Ökar Tillgängligheten', text: '', link: 'då' },
                { img: 'slide3', header: 'Ökad synlighet genom Jamiis publika sida', text: '', link: 'korv' },
            ]
        }
    },
    methods:
    {
      
    },
    computed: {
        url() { return config.urlToServer },         
        height() { return window.innerHeight },
    },
    watch:
    {
        windowScroll(newvalue)
        {
            var self = this;
            if(newvalue > 200)
            { 
                setTimeout(() => {
                    
                    self.showHeader = true;
                }, 300);            
            }
            
            if(newvalue > 400)
            { 
                setTimeout(() => {
                    
                    self.showContent = true;
                }, 300);            
            }
        }
    }
}
</script>
<template>   
        <section class="SaleHomeWhatIs" id="SaleHomeWhatIs" :style="{'background': bgColor}">
            <div class="SaleHomeWhatIs-content">
                <div class="SaleHomeWhatIs-top">
                    <div class="SaleHomeWhatIs-header-and-links" :class="showHeader ? 'SaleHomeWhatIs-header-and-links-visible' :''">
                        <h1>Vad får du av Jamii?</h1>
                        <ul>
                            <li v-for="link in links" @click="slide = link.id" :key="link.id" :class="link.id == slide ? 'SaleHomeWhatIs-link-selected' : ''"> {{ link.title }}</li>
                        </ul>
                    </div>
                </div>
                <div class="SaleHomeWhatIs-slider-area">
                    <div class="SaleHomeWhatIs-slider-area-div" :class="[slide == 1 ? 'SaleHomeWhatIs-slider-area-left':'', slide == 2 ? 'SaleHomeWhatIs-slider-area-middle':'', slide == 3 ? 'SaleHomeWhatIs-slider-area-right':'', ]">
                        <!-- slide 1 -->
                        <div class="g-33">
                            <h2>Gör Föreningslivet Mer Levande Med Jamii</h2> 
                            <div class="SaleHomeWhatIs-wrapper">
                                <div class="SaleHomeWhatIs-img">
                                    <img src="@/assets/imgs/compz2.png" @click="selectedImage = 'slide1'" />                              
                                </div>                           
                                <div class="SaleHomeWhatIs-text">                      
                                    <p>Med Jamii tar vi er förening till en helt ny nivå. Vår plattform är fullpackad med
                                        smarta funktioner designade för att göra kommunikationen i ditt område både enklare och mer engagerande. 
                                    </p>
                                    <p>Här är hur vi gör det möjligt:</p>
                                    <ul>
                                        <li><strong>Forum:</strong>&nbsp;Vårt forum är platsen där du kan dela nyheter, ställa frågor och diskutera allt som rör er förening. Det är som en digital anslagstavla, men mycket bättre.</li>
                                        <li><strong>Privata meddelanden:</strong>&nbsp;Ibland behöver man ta det på tu man hand. Våra privata meddelanden gör det enkelt att hålla kontakten med föreningsvänner, vare sig det handlar om att låna en stege eller att planera en överraskningsfest.</li>
                                        <li><strong>Inbjudningar:</strong>&nbsp;Oavsett om det är en sommarfest eller ett årsmöte, gör vår inbjudningsfunktion det lätt som en plätt att sprida ordet och få med alla. Skapa, skicka ut, och håll koll på vem som kommer – allt på ett ställe.</li>
                                        <li><strong>Dokumenthantering:</strong>&nbsp;Viktiga papper och dokument för föreningen? Inga problem. Med vår dokumenthantering håller ni enkelt ordning på allt från protokoll till underhållsplaner, tillgängliga för alla medlemmar när som helst.</li>
                                    </ul>
                                    <p>Med Jamii blir det lättare än någonsin att bygga upp och underhålla en stark och kommunikativ förening.</p>                                   
                                </div>
                            </div>
                        </div>
                        <!-- slide 2 -->
                        <div class="g-33">
                            <h2>Jamii Ökar Tillgängligheten</h2> 
                            <div class="SaleHomeWhatIs-wrapper">
                                <div class="SaleHomeWhatIs-img">
                                    <img src="@/assets/imgs/booking-example.png" @click="selectedImage = 'slide2'" />                                                            
                                </div>                          
                                <div class="SaleHomeWhatIs-text">                      
                                    <p>Jamii tar tillgängligheten till en helt ny nivå och gör livet i föreningen smidigare för alla. </p>
                                    <p>Här är hur:</p>
                                    <ul>
                                        <li><strong>Bokningsfunktion för gemensamma tillgångar med extern tillgänglighet: </strong>Med Jamii kan medlemmarna enkelt boka föreningens gemensamma utrymmen och faciliteter, som bastun och festlokalen, med bara några få klick. Vi har även öppnat upp möjligheten för personer utanför föreningen att boka dessa tillgångar genom en publikt tillgänglig sida. Detta skapar en unik möjlighet för föreningen att välkomna externa användare och integrera föreningen ytterligare med det omgivande samhället, samtidigt som det ger en möjlighet till extra intäkter eller större delaktighet i lokala evenemang och aktiviteter.</li>
                                        <li><strong>Direkt tillgång till styrelsen och arbetsgrupper:</strong>&nbsp;Vi gör det enklare än någonsin att komma i kontakt med de som driver föreningen framåt. Genom att ge dessa grupper egna forum säkerställer vi att de är lätta att nå för alla medlemmar, vilket förbättrar både kommunikation och samarbete.</li>
                                    </ul>
                                    <p>Jamii är designad för att göra föreningslivet mer tillgängligt och engagerande, genom att förenkla de dagliga processerna och stärka banden mellan medlemmarna.</p>                                
                                </div>
                            </div>
                        </div>
                        
                        <!-- slide 3 -->
                        <div class="g-33">
                            <h2>Ökad synlighet genom Jamiis publika sida</h2> 
                            <div class="SaleHomeWhatIs-wrapper">
                                <div class="SaleHomeWhatIs-img">
                                    <img src="@/assets/imgs/public-homz.png" @click="selectedImage = 'slide3'" style="width: 60%"/>                             
                                </div>
                                <div class="SaleHomeWhatIs-text">                      
                                    <p>Den publika sida på Jamii är designad för att öppna upp föreningen mot omvärlden och visa upp allt det man har att erbjuda. Här är vad du kan förvänta dig:</p>
                                    <ul style="padding: 20px;">
                                        <li><strong>Publik bokning:</strong>&nbsp;Gör det möjligt för personer utanför föreningen att enkelt boka tillgång till era gemensamma utrymmen och faciliteter, vilket breddar er kontakt med det omgivande samhället.</li>
                                        <li><strong>Aktuellt:</strong>&nbsp;Håller alla uppdaterade med de senaste nyheterna och händelserna inom er förening. Det är ett perfekt sätt att dela med er av era framgångar och kommande evenemang.</li>
                                        <li><strong>Bildgalleri:</strong>&nbsp;Ett fönster in till er förening där ni kan visa upp bilder från evenemang, eller andra höjdpunkter som gör föreningen unik.</li>
                                        <li><strong>Möjlighet att beskriva föreningen:</strong>&nbsp;Ger er en plattform för att berätta föreningens historia, beskriva värderingar och mål, samt framhäva de saker som gör föreningen speciell och inbjudande för nya medlemmar och besökare.</li>
                                    </ul>                                
                                </div>
                            </div>
                        </div>
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        <!-- <div v-for="content in contents" class="SaleHomeWhatIs-slider-area-slide">
                            <div class="SaleHomeWhatIs-area" :class="showContent ? 'SaleHomeWhatIs-area-visible' :''">           
                                            
                            <div class="SaleHomeWhatIs-img" @click="selectedImage = content.img"  :style="{ 'background-image': 'url(' +this[content.img] + ')' }"></div>
                            <div class="SaleHomeWhatIs-text">     
                                <div v-if="content.img == 'slide1'" class="SaleHomeWhatIs-content">
                                    <p>Med Jamii tar vi er förening till en helt ny nivå. Vår plattform är fullpackad med
                                        smarta funktioner designade för att göra kommunikationen i ditt område både enklare och mer engagerande. 
                                    </p>
                                    <p>Här är hur vi gör det möjligt:</p>
                                    <ul style="padding: 20px;">
                                        <li><strong>Forum:</strong>&nbsp;Vårt forum är platsen där du kan dela nyheter, ställa frågor och diskutera allt som rör er förening. Det är som en digital anslagstavla, men mycket bättre.</li>
                                        <li><strong>Privata meddelanden:</strong>&nbsp;Ibland behöver man ta det på tu man hand. Våra privata meddelanden gör det enkelt att hålla kontakten med föreningsvänner, vare sig det handlar om att låna en stege eller att planera en överraskningsfest.</li>
                                        <li><strong>Inbjudningar:</strong>&nbsp;Oavsett om det är en sommarfest eller ett årsmöte, gör vår inbjudningsfunktion det lätt som en plätt att sprida ordet och få med alla. Skapa, skicka ut, och håll koll på vem som kommer – allt på ett ställe.</li>
                                        <li><strong>Dokumenthantering:</strong>&nbsp;Viktiga papper och dokument för föreningen? Inga problem. Med vår dokumenthantering håller ni enkelt ordning på allt från protokoll till underhållsplaner, tillgängliga för alla medlemmar när som helst.</li>
                                        </ul>
                                        <p>Med Jamii blir det lättare än någonsin att bygga upp och underhålla en stark och kommunikativ förening.</p>

                                </div>
                                <div v-if="content.img == 'slide2'" class="SaleHomeWhatIs-content">
                                    <p>Jamii tar tillgängligheten till en helt ny nivå och gör livet i föreningen smidigare för alla. </p>
                                    <p>Här är hur:</p>
                                    <ul style="padding: 20px;">
                                        <li><strong>Bokningsfunktion för gemensamma tillgångar med extern tillgänglighet: </strong>Med Jamii kan medlemmarna enkelt boka föreningens gemensamma utrymmen och faciliteter, som bastun och festlokalen, med bara några få klick. Vi har även öppnat upp möjligheten för personer utanför föreningen att boka dessa tillgångar genom en publikt tillgänglig sida. Detta skapar en unik möjlighet för föreningen att välkomna externa användare och integrera föreningen ytterligare med det omgivande samhället, samtidigt som det ger en möjlighet till extra intäkter eller större delaktighet i lokala evenemang och aktiviteter.</li>
                                        <li><strong>Direkt tillgång till styrelsen och arbetsgrupper:</strong>&nbsp;Vi gör det enklare än någonsin att komma i kontakt med de som driver föreningen framåt. Genom att ge dessa grupper egna forum säkerställer vi att de är lätta att nå för alla medlemmar, vilket förbättrar både kommunikation och samarbete.</li>
                                    </ul>
                                    <p>Jamii är designad för att göra föreningslivet mer tillgängligt och engagerande, genom att förenkla de dagliga processerna och stärka banden mellan medlemmarna.</p>
                                </div>
                                <div v-if="content.img == 'slide3'" class="SaleHomeWhatIs-content">
                                    <p>Den publika sida på Jamii är designad för att öppna upp föreningen mot omvärlden och visa upp allt det man har att erbjuda. Här är vad du kan förvänta dig:</p>
                                    <ul style="padding: 20px;">
                                        <li><strong>Publik bokning:</strong>&nbsp;Gör det möjligt för personer utanför föreningen att enkelt boka tillgång till era gemensamma utrymmen och faciliteter, vilket breddar er kontakt med det omgivande samhället.</li>
                                        <li><strong>Aktuellt:</strong>&nbsp;Håller alla uppdaterade med de senaste nyheterna och händelserna inom er förening. Det är ett perfekt sätt att dela med er av era framgångar och kommande evenemang.</li>
                                        <li><strong>Bildgalleri:</strong>&nbsp;Ett fönster in till er förening där ni kan visa upp bilder från evenemang, eller andra höjdpunkter som gör föreningen unik.</li>
                                        <li><strong>Möjlighet att beskriva föreningen:</strong>&nbsp;Ger er en plattform för att berätta föreningens historia, beskriva värderingar och mål, samt framhäva de saker som gör föreningen speciell och inbjudande för nya medlemmar och besökare.</li>
                                    </ul>                          
                                </div>
                            </div>
                        </div>
                        </div> -->
                    </div>
                </div>  
            </div>     
        </section>
        <PopUp v-if="selectedImage != null" :width="'95%'">
            <div class="text-center">
                <button class="btn float-right" style="margin-bottom:10px;" @click="selectedImage = null">Stäng</button>
                <img v-if="selectedImage == 'slide1'" src="@/assets/imgs/compz2.png" style="max-width: 800px; width: 100%"/>                
                <img v-if="selectedImage == 'slide2'" src="@/assets/imgs/booking-example.png" style="width: 100%"/>                
                <img v-if="selectedImage == 'slide3'" src="@/assets/imgs/public-homz.png" style="width: 100%"/>                
            </div>
        </PopUp>
</template>
<style scoped>

.SaleHomeWhatIs-header-and-links
{
    opacity: 0;
    margin-top: 100px;
}

.SaleHomeWhatIs-header-and-links-visible
{
    opacity: 1;
    margin-top: 0px;
    transition: ease 1.4s;
}

.SaleHomeWhatIs
{   
    overflow: auto;
}

.SaleHomeWhatIs-top
{
    height: 160px; 
    overflow: hidden;
}

.SaleHomeWhatIs-content
{
    width: 80%;
    margin: auto;
    margin-bottom: 50px;
    margin-top:50px;
    color: white;
}

.SaleHomeWhatIs-wrapper
{
    display: flex;
    align-items: top;
}

.SaleHomeWhatIs-img
{
    width: 40%;
    cursor:pointer;
    text-align: center;
}

.SaleHomeWhatIs-img img
{
   width: 100%;
}

.SaleHomeWhatIs-text
{
    width: calc(60% - 30px);
    padding:0px 0px 0px 30px;
}

.SaleHomeWhatIs-area
{
    opacity: 0;
}

.SaleHomeWhatIs-area-visible
{
    opacity: 1 !important;
    transition: linear 1s;
}

.SaleHomeWhatIs-slider-area
{
    width:100%;
    overflow: hidden;
    line-height: 30px; 
    font-size: 17px; 
}

.SaleHomeWhatIs-slider-area-div
{
    width: 300%;
    display: flex;
}


.SaleHomeWhatIs-slider-area-left
{
    margin-left: 0%;
}
.SaleHomeWhatIs-slider-area-middle
{
    margin-left: -100%;
}

.SaleHomeWhatIs-slider-area-right
{
    margin-left: -200%;
}

.SaleHomeWhatIs-slider-area-left, .SaleHomeWhatIs-slider-area-right, .SaleHomeWhatIs-slider-area-middle
{
    transition: ease 1s;
}

.SaleHomeWhatIs-top ul
{
    list-style: none;
    display: flex;
    justify-content:center;
}

.SaleHomeWhatIs-top li
{
    display: inline-block;
    margin: 0px 20px;
    padding-bottom: 15px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
}

.SaleHomeWhatIs-top li:hover, .SaleHomeWhatIs-link-selected
{
    padding-bottom: 12px !important;
    border-bottom: 3px solid rgb(255, 255, 255);
}

h1
{
    font-size: 23pt;
    text-align: center;
}

h2
{
    font-size: 18pt;
    text-align: center;
}

@media only screen and (max-width: 1400px) {
    .SaleHomeWhatIs-top li
    {
    
        font-size: 13px;
        font-weight: bold;
    }
}

    @media only screen and (max-width: 1000px) {

        h1 {
            font-size: 19pt;
        }

        h2 {
            font-size: 16pt !important;
        }

        .g-33
        {
            width: 100%;
        }

        .SaleHomeWhatIs
        {          
            overflow: auto;
        }

        .SaleHomeWhatIs-content
        {
            margin-top: 50px;
            width: 90%;
            margin: auto;
        }

        ul{
            padding: 20px;
            }

        .SaleHomeWhatIs-wrapper
        {
            flex-direction: column;
        }

        .SaleHomeWhatIs-img
        {
            width: 100%;
        }

        .SaleHomeWhatIs-slider-area-right .SaleHomeWhatIs-img
        {
            height: 500px !important;
        }
        .SaleHomeWhatIs-text
        {
            width: 100%;
            display: block;
            padding:0px 0px 40px 0px;
        }

        .SaleHomeWhatIs-text h2
        {
            margin: 30px 0px 10px 0px;
        }

        .SaleHomeWhatIs ul
        {
           
        }

        ul {
           padding: 0px;
        }

        li {
            margin-top: 15px;
        }

        .SaleHomeWhatIs-slider-area-div
        {
                width: 100%;
                display: block;
        }

        .SaleHomeWhatIs-slider-area-slide
        {
            width: 100%; 
            display: block; 
            overflow: auto;
            margin-bottom: 30px;
        }

        .SaleHomeWhatIs-top
        {
            margin-top: 30px;
            height: 70px; 
        }
    }
</style>
