<script>
export default {
    created() {  
    },
    data() {
        return { 
            showTab: 1,
            completedTab1Once: false,
            orderItem: { first_name: null, last_name: null, email: null, mobile_no: null, name: null, confirm: false, is_trial: true },
            validationErrors: [],
            showConfirmationMissing: false,
            propNames: { first_name: 'Förnamn', last_name: 'Efternamn', email: 'Email', name: 'Föreningens namn'},
            orderSuccess: false
        }
    },  
    methods:
    {
        goToTab2()
        {
            var self = this;
            this.validationErrors = [];
            for (const prop in this.orderItem) {
                if(self.orderItem[prop] == null && prop != 'mobile_no')
                {
                    self.validationErrors.push(prop);
                }
                
            }

            if(this.validationErrors.length == 0)
            {
                this.showTab = 2;
                this.completedTab1Once = true;
                setTimeout(() => {
                    self.scrollToElement('SaleTrial-details');
                }, 400);
            }
        },
        order()
        {
            this.showConfirmationMissing = false;
            if(!this.orderItem.confirm)
            {
                this.showConfirmationMissing = true;
                return;
            }

            var self = this;
            var data = { order_item: this.orderItem  }
            this.$communicator.post("order/", this.orderItem)
            .then(function(data)
                {
                    self.orderSuccess = true;
                    $("html, body").animate({ scrollTop: 0 });
                })
                .fail(function(error) {
                
                }); 
        },
        scrollToElement(elementId) {
            var scrollTop = document.getElementById(elementId).getBoundingClientRect().top + document.documentElement.scrollTop - 100;
            $("html, body").animate({ scrollTop: scrollTop });
        }
    }, 
    computed:
    {
        height() { return window.innerHeight + 'px' },
    },
}
</script>
<template>
  <div class="SaleOrder-area" style="overflow: auto;" :style="{'min-height': this.$windowHeight-200 + 'px'}">
    <section class="SaleOrder" v-fade-in>
      <div class="margin-top-15" v-if="!orderSuccess">
        <h1>'Prova på' Jamii</h1>
        <p style="line-height: 26px;">
          Tack för att du väljer att prova Jamii! Vårt provpaket ger dig tillgång till digitala verktyg designade för att förenkla och effektivisera hanteringen av din förening.
           Med paketet får du full åtkomst till vår plattform för att enkelt kunna hantera bokningar, kommunikation och dokumentation.
            Vi erbjuder detta provpaket <b>helt utan kostnad under en period på 30 dagar.</b> Observera att testperioden inte automatiskt övergår till en betalande prenumeration. 
            Efter testperioden har du möjlighet att välja om du vill fortsätta använda tjänsten till en kostnad av <b>200 kr per månad</b> (betalas årsvis).
        </p>
       
        <h2 :style="{ 'color': showTab != 1 ? '#ddd' : '', 'cursor': showTab != 1 ? 'pointer': '' }" @click="showTab = 1" style="margin-top: 30px;">Steg 1: Kontaktuppgifter</h2>
        <div v-if="showTab == 1">
          <fieldset>
            <legend>Kontaktperson</legend>
            <div class="g-50">
              <label>Förnamn <span>*</span></label>
              <input type="text" v-model="orderItem.first_name" />
            </div>
            <div class="g-50">
              <label>Efternamn <span>*</span></label>
              <input type="text" v-model="orderItem.last_name" />
            </div>
            <div class="g-50">
              <label>Email <span>*</span></label>
              <input type="text" v-model="orderItem.email" />
            </div>
            <div class="g-50">
              <label>Telefon</label>
              <input type="text" v-model="orderItem.mobile_no" />
            </div>
          </fieldset>
          <fieldset>
            <legend>Föreningens namn <span>*</span></legend>
            <input type="text" class="margin-top-15" v-model="orderItem.name" />
          </fieldset>
          <label>&nbsp; <span>*</span> = obligatoriskt fält</label>
          <div class="Messages-validation-error-area" v-if="validationErrors.length" v-fade-in>
            <div style="padding: 15px;">
              Oj, det ser ut som att du glömt att mata in något obligatoriskt fält.
              <ul>
                <li v-for="error in validationErrors" :key="error">
                  {{ propNames[error] }}
                </li>
              </ul>
            </div>
          </div>
          <button v-show="!completedTab1Once" @click="goToTab2">Vidare till nästa steg</button>
        </div>

        <h2 :style="{ 'color': showTab != 2 ? '#ddd' : '', 'cursor': showTab != 2 ? 'pointer': '' }" @click="showTab = 2" v-show="completedTab1Once" id="SaleTrial-details">Steg 2: Sammanfattning</h2>
        <div v-if="showTab == 2" v-fade-in>
          
          <p>Tack för att du väljer att prova på Jamii!</p>
         
          <h3>Beställningsdetaljer</h3>
          <ul class="SaleTrial-order-details">
            <li><strong>Förnamn:</strong> {{ orderItem.first_name }}</li>
            <li><strong>Efternamn:</strong> {{ orderItem.last_name }}</li>
            <li><strong>Email:</strong> {{ orderItem.email }}</li>
            <li><strong>Telefon:</strong> {{ orderItem.mobile_no }}</li>
            <li><strong>Föreningens namn:</strong> {{ orderItem.name }}</li>
            <li><strong>'Prova på'-erbjudande:</strong> Ja</li>
          </ul>
          <fieldset class="margin-top-15px">
            <legend>Användarvillkor</legend>
            <p><strong>Genom att godkänna dessa villkor accepterar du följande:</strong></p>
            <ul>
              <li>Du samtycker till att använda Jamii enligt gällande lagar och regler samt våra användarriktlinjer.</li>
              <li>Du förstår att information som delas på plattformen kan vara tillgänglig för andra användare inom din förening.</li>
              <li>Du samtycker till behandling av personuppgifter i enlighet med vår integritetspolicy, som syftar till att skydda din information och tillhandahålla tjänsten.</li>
              <li>Eventuella betalningar för tjänsten efter provperioden regleras enligt de betalningsvillkor som anges.</li>
              <li>Jamii förbehåller sig rätten att när som helst ändra dessa villkor, med förbehåll för att informera användarna i god tid.</li>
              <li>Ditt fortsatta användande av tjänsten efter sådana ändringar innebär ett godkännande av de nya villkoren.</li>
            </ul>
            <label><input type="checkbox" v-model="orderItem.confirm" style="width: 20px; display: inline-block; margin-top: 10px;" /> Jag godkänner användarvillkoren.</label>
          </fieldset>
          <div class="Messages-validation-error-area" v-if="showConfirmationMissing" v-fade-in>
            <div style="padding: 15px;">
              Du måste godkänna villkoren
            </div>
          </div>
          <button @click="order">Beställ</button>
        </div>

      </div>

      <div v-if="orderSuccess">
        <h1>Beställningen är skickad! <span style="font-size: 30pt;">&#127870;</span></h1>
        <p>Så kul att ni valt att testa på att digitalisera er förening med oss på Jamii. En bekräftelse kommer att skickas ut till angiven e-mail och senast inom ett dygn kommer ni få inloggningsuppgifterna.</p>
        <p>Har ni några frågor eller något ni undrar över är det bara att höra av sig till <a href="mailto:admin@jamii.se">admin@jamii.se</a>.</p>
        <p>Vi ses i Jamii!</p>
        <p>//Jamii-gänget</p>
      </div>
    </section>
  </div>
</template>
<style scoped>
li
{
    line-height: 27px;
}

ul {
  padding: 0px 20px;
}

input{
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13pt;
    padding: 10px;
}

fieldset
{
    border: 1px solid #ddd;
    margin-top: 33px;
    padding: 4px 15px 15px 15px; 
    text-align: left;
    background-color: white;
}

.SaleTrial-order-details
{
    list-style: none;padding: 0px;line-height: 23px;
}

legend{
    font-weight: 600;
    font-size: 13px;
    text-align: left;
    text-transform: uppercase;
}

label{
    margin: 10px 0px 5px 0px;
}

label span{
    font-family: Arial, Helvetica, sans-serif;
}

.SaleOrder
{
    width: 90%;
    margin: auto;
    margin-top: 90px;
    overflow: auto;   
    max-width: 1000px;
}

section{
    color:  rgba(31, 57, 87, 1);
}

h2
{
    font-size: 13pt;
    
}

h2:hover {
    
    color:  rgba(31, 57, 87, 1);
   
}

h1{
    margin:50px 0px 30px 0px;
}

button{
        padding:12px 30px;
        margin: 40px 40px 40px 0px;
        font-size: 15px;
        font-family: 'Raleway', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: rgb(225, 182, 117);
        color: white;
        border: 0px;
        border-radius: 6px;
        cursor: pointer;
        letter-spacing: 1px;
    }

.Messages-validation-error-area
{
    border: 1px solid rgb(230, 70, 70);
    background-color: rgb(252, 218, 218) !important;
    color: rgb(230, 70, 70);
    margin-top: 25px;
}

@media (-webkit-device-pixel-ratio: 1.25) {
    
    h2 {
      font-size: 11pt;
    }

    .SaleOrder
    {
        margin-top: 60px;

    }
}
</style>
