<script>
export default {
    props: ['width', 'color', 'opacity', 'marginAuto', 'useBlackLogo'],
    
}
</script>
<template>
<div class="Logo-wrapper" :style="{ 'width': width + 'px', 'height': width + 'px', 'opacity': opacity != undefined ? opacity : 1, 'margin': marginAuto ? 'auto' : '' }">
    <img v-if="!useBlackLogo" src="@/assets/imgs/white.png" :style="{ 'width': width + 'px', 'height': width + 'px'}">
    <img v-if="useBlackLogo" src="@/assets/imgs/black.png" :style="{ 'width': width + 'px', 'height': width + 'px'}">
    <div class="Logo-bg" :style="{ 'width': width - 5 + 'px', 'height': width - 5 + 'px', 'background-color': color, 'border-radius': width/2 + 'px' }"></div>
</div>
</template>

<style scoped>
.Logo-wrapper{
    position: relative;   
}
img{
      position: absolute;
      left: 0px;
      z-index: 2;
    }
    .Logo-bg{
    left: 2px;
    top: 2px;
    border-radius: 74px;
    overflow: hidden;
    position:absolute;
    z-index: 1;
  }
</style>