<script>
export default {
    created() {  
    },
    data() {
        return { 
            demoItem: { first_name: null, last_name: null, email: null, mobile_no: null },
            demoSuccess: false
        }
    },   
    methods:
    {
        sendDemoRequest()
        {
            
            var self = this;
            
            this.$communicator.post("demo/", this.demoItem)
            .then(function(data)
                {
                    self.demoSuccess = true;
                })
                .fail(function(error) {
                
                }); 
        }
    },
    computed:
    {
        height() { return window.innerHeight + 'px' },
    },
}
</script>
<template>
    <div class="SaleDemo-area" style="overflow: auto;" :style="{'min-height': this.$windowHeight-200 + 'px'}">
        <section class="SaleDemo" v-fade-in>
            <div class=" margin-top-15">
                <h1>Demo av Jamii</h1>
                Känner du att detta är något för eran förening men är inte riktigt övertygad? Skicka en förfrågan till oss så bokar vi upp ett möte. 
                Vi älskar att prata om vår produkt både på plats och i online-möten. Använd formuläret nedan så hörs vi!

                <div v-if="!demoSuccess">                
                    <fieldset>
                        <div class="g-50">
                        <label>Förnamn</label>
                        <input type="text" v-model="demoItem.first_name" />
                        </div>
                        <div class="g-50">
                        <label>Efternamn </label>
                        <input type="text" v-model="demoItem.last_name" />
                        </div>
                        <div class="g-50">
                        <label>Email</label>
                        <input type="text" v-model="demoItem.email" />
                        </div>
                        <div class="g-50">
                        <label>Telefon</label>
                        <input type="text" v-model="demoItem.mobile_no" />
                        </div>
                    </fieldset>
                    <button @click="sendDemoRequest">Skicka förfrågan</button>
                </div>
                <div v-else class="SaleDemo-success">
                    <h2>Demo-förfrågan skickad!</h2>
                    Vi återkommer så snabbt vi kan så att vi tillsammans kan prata jamii.
                </div>

             </div>
         </section>
    </div>
</template>
<style scoped>

.SaleDemo-success
{
    margin: 45px 0px;
    border: 1px solid #ddd;
    padding: 20px;
    background: white;
}

a{
    
    color:  rgba(31, 57, 87, 1);
    width: calc(25% - 1px);
    float: left;
    position: relative;
}

h1 {
    margin: 50px 0px 30px 0px;
}
.SaleDemo
{
    width: 90%;
    margin: auto;
    margin-top: 90px;
    overflow: auto;  
    max-width: 1000px;
}

section{
    color:  rgba(31, 57, 87, 1);
}

button{
        padding:12px 30px;
        margin: 40px 40px 40px 0px;
        font-size: 15px;
        font-family: 'Raleway', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: rgb(225, 182, 117);
        color: white;
        border: 0px;
        border-radius: 6px;
        cursor: pointer;
        letter-spacing: 1px;
    }

    input{
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13pt;
    padding: 10px;
}

fieldset
{
    border: 1px solid #ddd;
    margin-top: 33px;
    padding: 4px 15px 15px 15px; 
    text-align: left;
    background-color: white;
}


label{
    margin: 10px 0px 5px 0px;
}

label span{
    font-family: Arial, Helvetica, sans-serif;
}
</style>