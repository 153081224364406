<script>

import Logo from '@/components/Logo.vue'
export default {
    components: {Logo},
    created() {  
    },
    data() {
        return { 
           
        }
    },   
    methods:
    {
        goTopage(page)
        {
            $(window).scrollTop(0);   
                this.$router.push({ name: page })
                
        },
    }
}
</script>
<template>
    <div style="overflow: auto;" :style="{'min-height': this.$windowHeight-200 + 'px'}">
        <section class="SaleNews" v-fade-in>
            <h1>Nyheter</h1>

            <h2>2:a april 2024 - Samfällify byter namn till jamii</h2>
            <p>Det är med ett visst vemod vi säger hej då till samfällify. Destå gladare är vi att kunna säga hej till Jamii som tar över som ny kapten på skutan. 
               I och med att appen vill nå ut till alla typer av föreningar kändes ett namnbyte naturligt. </p><p>Befintliga kunder kommer inte drabbas nämnvärt av 
               namnbytet, möjligen nya färg här och där. 
               <br>
               <br>
               /Jamii-gänget
            </p>
        </section>
    </div>
</template>
<style scoped>

    h1{
        margin: 50px 0px 30px 0px;
    }

    h2{
        margin-top: 20px;
        font-size: 13pt;
    }

    .SaleNews
    {
        width: 90%;
        margin: auto;
        margin-top: 90px;
        max-width: 1000px;
        overflow: auto;
        color:  rgba(31, 57, 87, 1);
        margin-bottom: 50px;
    }

    @media only screen and (max-width: 1000px) {

    }


</style>