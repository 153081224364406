<script>

import Logo from '@/components/Logo.vue'
export default {
    components: {Logo},
    created() {  
    },
    data() {
        return { 
            backgrounds: this.$backgroundColors
        }
    },   
    methods:
    {
        goTopage(page)
        {
            $(window).scrollTop(0);   
                this.$router.push({ name: page })
                
        },
    }
}
</script>
<template>
    <div class="SaleCost-area" style="overflow: auto;" :style="{'min-height': this.$windowHeight-200 + 'px'}">
        <section class="SaleCost" v-fade-in>
            <div class="SaleCost-windows">                    
                <div class="SaleCost-sale-window">  
                    <Logo class="Menu-logo-placement" style="position: absolute; top: 20px; right: 20px;" :width="60" :color="this.$backgroundColors[1]" :marginAuto="true" :opacity="1"></Logo>
                   <b style="text-transform: uppercase">'Prova på'-Jamii</b>
                   <h1>Gratis</h1>
                   <div>
                        Gäller i 30 dagar, begränsat antal användare.
                        <br><br><br>
                        <ul>
                            <li>För dig som vill testa först innan du beställer</li>
                            <li>Full tillgång till hela appan och alla funktioner</li>
                            <li>Efter 30 dagar så väljer ni om ni vill fortsätta eller inte.</li>
                        </ul>
                   </div>
                   <button :style="{ 'background-color': this.$backgroundColors[1] }" @click="goTopage('saleTrial')">Testa gratis</button>  
                </div>
                <div class="SaleCost-sale-window">  
                    <Logo class="Menu-logo-placement" style="position: absolute; top: 20px; right: 20px;" :width="60" :color="this.$backgroundColors[2]" :marginAuto="true" :opacity="1"></Logo>
                    <b style="text-transform: uppercase">Jamii</b>
                    <h1>200 kr</h1>  
                    <div>
                        Per månad, faktureras årsvis. Max 500 användare.
                        <br><br><br>                
                        <ul>
                            <li>För dig som vill komma igång direkt</li>
                            <li>Vid beställning direkt bjuder vi på två månader.</li>
                            <li>Få möjlighet att påverka appens framtida utveckling.</li>
                        </ul>
                   </div>   
                   <button :style="{ 'background-color': this.$backgroundColors[2] }" @click="goTopage('saleOrder')">Beställ nu</button>                                     
                </div>
               
                <div class="SaleCost-sale-window">  
                    <Logo class="Menu-logo-placement" style="position: absolute; top: 20px; right: 20px;" :width="60" :color="this.$backgroundColors[3]" :marginAuto="true" :opacity="1"></Logo>
                    <b style="text-transform: uppercase">Jamii MAX</b>
                    <h1>FR. 500 kr</h1>  
                    <div>
                        Per månad, faktureras årsvis. <b>Obegränsat</b> antal användare.
                        <br><br><br>                
                        <ul>
                            <li>För dig som vill komma igång direkt</li>
                            <li>Vid beställning direkt bjuder vi på två månader.</li>
                            <li>Få möjlighet att påverka appens framtida utveckling.</li>
                        </ul>
                   </div>   
                   <a :style="{ 'background-color': this.$backgroundColors[3] }" href="mailto:admin@jamii.se">Kontakta oss</a>                                     
                </div>
               
             </div>
         </section>
    </div>
</template>
<style scoped>
.SaleCost-windows
{
    width: 100%;
    margin: auto;
    margin-top: 40px;
}
.SaleCost-success
{
    margin: 45px 0px;
    border: 1px solid #ddd;
    padding: 20px;
    background: white;
}

li {
    margin-bottom: 10px;
    line-height: 26px;
}

.SaleCost-sale-window
{
    position: relative;
    width: calc(33% - 142px);
    float: left;
    text-align: center;
    background-color: white;
    border-radius: 20px;
    padding: 100px 50px 30px 50px;
    margin: 20px 20px 60px 20px;
    border: 1px solid #ddd;
    box-shadow: 2px 2px 14px 0px #ddd;
}

.SaleCost-sale-window div
{
    text-align: left;

}
/* 
a{
    
    color:  rgba(31, 57, 87, 1);
    width: calc(25% - 1px);
    float: left;
    position: relative;
} */

.SaleCost
{
    width: 90%;
    margin: auto;
    margin-top: 90px;
    overflow: auto;
}

section{
    color:  rgba(31, 57, 87, 1);
}

button, a {
        display: block;
        padding:12px 30px;
        margin: auto;
        margin-top: 40px;
        margin-bottom: 40px;
        font-size: 15px;
        font-family: 'Raleway', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: rgb(225, 182, 117);
        color: white;
        border: 0px;
        border-radius: 6px;
        cursor: pointer;
        letter-spacing: 1px;
        width: fit-content;
    }

    a:hover{
        text-decoration: none;
        margin-top: 39px;
        margin-bottom:39px;
        padding: 13px 31px;
        filter:brightness(1.1)
    }

    button:hover 
    {
        margin-top: 39px;
        margin-bottom:39px;
        padding: 13px 31px;
        filter:brightness(1.1)
    }

    @media only screen and (max-width: 1000px) {
        .SaleCost-sale-window
        {
            width: calc(100% - 82px);
            margin: 0px 20px 40px 20px;
            padding: 100px 20px 30px 20px;
        }

        .SaleCost-windows
        {
            width: 100%;
            margin: auto;
            margin-top: 10px;
        }

        ul{
            padding-left: 19px;
        }
    }


</style>
