<script>
export default {
}
</script>
<template>   
    <section class="SaleHomeWhoAreWe" id="SaleHomeWhoAreWe">
        <div class="g-100">
            <div class="SaleHomeWhoeAreWe-text-area">
                <h1>Jamii-gänget</h1>
                
                Tjena! Vi är gänget bakom Jamii. 
                Vi älskar teknik, men tycker att den ska vara superenkel, prisvärd och kul. 
                Vår app? Den är som en digital mötesplats för dig och dina vänner. 
                Inget krångel, bara ett smidigt sätt att hålla ihop föreningen. 
                Vi tror på teknik som känns mänsklig och gör vardagen lite bättre 
                där vi ser era åsikter som viktiga för appens vidare utveckling. 
                <br>
                <br>
                Häng med oss och gör din förening lite mer levande!                  
            </div>
        </div>
    </section>
</template>
<style scoped>
.SaleHomeWhoeAreWe-text-area{
    text-shadow: 1px 1px 2px rgb(88, 82, 83);
    margin: 400px 50px 200px 50px; 
    padding: 40px; 
    backdrop-filter: blur(10px); 
    background-color: rgba(0,0,0,0.25);
    color: rgb(255, 255, 255);
    border-left: 6px solid white; 
}

.SaleHomeWhoAreWe
{
    background-image: url('@/assets/imgs/we.png');
    background-size: cover;
    background-position: center;

    margin: auto;
    overflow: auto;
    background-color: rgb(219 243 255);
    color:  rgba(31, 57, 87, 1);
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 28px;
    font-weight: 500;
    line-height: 45px
}

h1{
    font-size: 30pt;
}

@media only screen and (max-width: 1000px) {
    .SaleHomeWhoAreWe
    {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
        margin-bottom: 0px;
        margin-top: 0px;
    }
    
    .SaleHomeWhoAreWe h1
    {
        font-size: 23pt;
    }
    .SaleHomeWhoeAreWe-text-area {
        text-shadow: 1px 1px 2px rgb(88, 82, 83);
        margin: 200px 20px 20px 20px; 
        padding: 20px; 
        backdrop-filter: blur(10px); 
        color: rgb(255, 255, 255);
        border-left: 0px solid white; 
        text-align: center;
        font-size:19px;
        line-height: 30px;
    }
}    
</style>