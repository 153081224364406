<script>
export default {
    created() {  
    },
    data() {
        return { 
        }
    },   
    computed:
    {
        height() { return window.innerHeight + 'px' },
    },
}
</script>
<template>
    <div class="SaleHistory-area" style="overflow: auto;" :style="{ 'height': height }">
        <section class="SaleHistory" v-fade-in>
            <div class=" margin-top-15">
                <h1>Vår Historia</h1>
                           
             </div>
         </section>
    </div>
</template>
<style scoped>

.SaleHistory-area
{
    background-image: 
        linear-gradient(
        100deg,
        rgba(31, 57, 87, 0.97) 0%,    /* Helt vit från start */
        rgba(31, 57, 87, 0.97) 35%,   /* Helt vit tills 30% av elementet */
        rgba(255, 255, 255, 0) 99%,   /* Början på genomskinlighet vid 70% */
        rgba(255, 255, 255, 0) 100%   /* Helt genomskinlig vid slutet */
        ),
        url('@/assets/imgs/Bryggan.jpg');
    background-position: center;
    background-size: cover;
}

a{
    
    color:  rgba(31, 57, 87, 1);
    width: calc(25% - 1px);
    float: left;
    position: relative;
}

.SaleHistory
{
    width: 80%;
    margin: auto;
    margin-top: 90px;
    overflow: auto;
    backdrop-filter: blur(10px);
    height: 500px;
}

section{
   color: white;
}

h1
{
    font-size: 30pt;
   
}

button{
        padding:12px 30px;
        margin: 40px 40px 0px 0px;
        font-size: 15px;
        font-family: 'Raleway', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: rgb(225, 182, 117);
        color: white;
        border: 0px;
        border-radius: 6px;
        cursor: pointer;
        letter-spacing: 1px;
    }
</style>