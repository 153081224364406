<script>
import config from "@/helpers/config"
import ImageGalleryPopUp from '@/components/ImageGalleryPopUp.vue'
export default {
    components: { ImageGalleryPopUp },
    data() {
        return {  
            selectedImage: { image: null },
            imgHeight: $(window).height() - 100,
        }
    }, 
    created() {  
        this.$cache.createCache('publicPage', 'public-page/', {id: this.$route.params.id });      
       
    },
    computed: {
        url() { return config.urlToServer },  
        publicPage() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPage : {} },
        images() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.images : {} },
        height() { return window.innerHeight/1.3 + 'px' },
  }
}
</script>
<template>
   <section v-fade-in>
        <div class="top-public" :style="{ 'background-image': publicPage.image_gallery_image_name != null ? 'url(' + url + 'uploads/'+ publicPage.customer_id+'/images/'+ publicPage.image_gallery_image_name + ')' : '', 'height': height}">
        
        </div>
        <div :style="{ 'background-color': publicPage.background_color }">
            <div class="text-section">
                <h1 :style="{ 'color': publicPage.header_color }">Bildgalleriet</h1>
                <div class="overflow-auto" style="padding: 10px;">
                    <div v-for="image in images" class="ImageGallery-img-area" :style="{ 'border-color': publicPage.background_color }">              
                        <img :src="url + 'uploads/'+ publicPage.customer_id+'/images/thumbnails/'+ image.name" @click="selectedImage.image = image" />               
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selectedImage.image != null">
            <ImageGalleryPopUp :selectedImage="selectedImage" :images="images">
            </ImageGalleryPopUp>
        </div>
   </section>
</template>
<style scoped>
.ImageGallery-img-area
{
    float: left;   
    /* border: 1px solid white; */
    color: white;
    font-size: 11px;
    position: relative;
    border: 5px solid green;
    width: calc(25% - 10px);
    max-height: 150px;
    overflow: hidden;
}

.ImageGallery-img-area img
{
    cursor: pointer;
    width: 100%;
    margin: auto;
}

.ImageGallery-img-area img:hover
{
    width: 105%;
    transition: width 0.2s;
}

.ImageGallery-highlighted-image
{
    text-align: center;
    position: relative;
}
.ImageGallery-highlighted-image img
{
    max-width: 100%;
}
.ImageGallery-highlighted-image button
{
    position: absolute;
    right: 10px;
    top: 10px;
}

@media only screen and (max-width: 900px) {
    .ImageGallery-img-area
    {
    width: calc(50% - 10px);
    max-height: 150px;
    overflow: hidden;
    min-height: 150px;
    }
}
</style>