<script>
export default {   
    computed:
    {
        message()
        {
            return this.$store.state.message;
        }
    },
    methods:
    {
        close() 
        {
            this.$store.commit('setEntity', { entityName: 'message', entity: null });
        }
    }
}
</script>
<template>
    <div v-if="message != null" class="Messages-container" v-fade-in="200">
        <div v-if="message.type == 'success'" class="Messages-success Messages-area">
           {{ message.successMessage != undefined ? message.successMessage : 'Härligt! Dina ändringar sparades utan problem.' }}
        </div>
        <div v-if="message.type == 'info'" class="Messages-info Messages-area">
            <div class="float-right">
                <i class="fa-solid fa-xmark" @click="close"></i>
            </div>
           {{ message.message }}
        </div>
        <div v-if="message.type == 'errors'" class="Messages-validation-error-area Messages-area">
            <div class="float-right">
                <i class="fa-solid fa-xmark" @click="close"></i>
            </div>
            Oj, du har något att rätta till innan du kan spara:
            <ul>
                <li v-for="error in message.errors">
                    {{ error }}
                </li>
            </ul>
        </div>
    </div>
</template>
<style scoped>
   .Messages-container
   {
        position: fixed;
        z-index: 22001;
        top: 30px;
        left: 0px;
        right: 0px;
        width: 70%;
        margin: auto;
        box-shadow: 1px 1px 1px #ddd;
   }

   .Messages-area {
        border-radius: 5px;
        text-align: left;
        padding:20px;    
        font-size: 14px;
   }

   .Messages-success
    {
        border: 1px solid rgb(47, 85, 59);
        background-color: rgb(189, 252, 189) !important;
        color: rgb(40, 77, 49);
    }

    .Messages-info
    {
        border: 1px solid rgb(238, 161, 19);
        background-color: rgb(243, 212, 156) !important;
        color: rgb(160, 106, 5);
    }

    .Messages-validation-error-area
    {
        border: 1px solid rgb(230, 70, 70);
        background-color: rgb(252, 218, 218) !important;
        color: rgb(230, 70, 70);
    }
    .Messages-validation-error-area i 
    {
        color: rgb(230, 70, 70);
        cursor: pointer;
    }
    
    @media only screen and (max-width: 1000px) {
        .Messages-content
        {
            width: 90%;
        }
    }

    .fa-xmark
    {
        cursor: pointer;
    }
</style>